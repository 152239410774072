import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/SEO"
import { GuidesPage } from "../../components/Guides/Guides"

const Guides: React.FC = () => (
  <Layout>
    <div>
      <SEO
        title="V4Scale guides"
        description="V4Scale is an Israeli company that expands the local R&D capabilities of leading Israeli and US technology companies with their diverse and skilled remote workforce from all over the world."
      />
      <GuidesPage />
    </div>
  </Layout>
)
export default Guides
