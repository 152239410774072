import { graphql, useStaticQuery } from "gatsby"

interface Guide {
  title: string
  slug: string
  description: { childMarkdownRemark: { html: string } }
  skills: string[]
}

interface GuideQueryData {
  allContentfulGuide: {
    nodes: Guide[]
  }
}

export const fetchGuidesQuery = (): GuideQueryData => {
  const data = useStaticQuery(graphql`
    query GuidesQuery {
      allContentfulGuide {
        nodes {
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          skills
        }
      }
    }
  `)
  return data
}
