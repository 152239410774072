import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import BackButton from "../BackButton/BackButton"
import Button from "../Button"
import Notification from "../Notification"
import SectionHeader from "../SectionHeader"
import * as styles from "./Guides.module.scss"
import { fetchGuidesQuery } from "../../graphql/queries/fetchArticles"
import { GuideItem } from "./GuideItem/GuideItem"

export const GuidesPage: React.FC = () => {
  const {
    allContentfulGuide: { nodes },
  } = fetchGuidesQuery()

  return (
    <div className={styles.main}>
      <section>
        <div className={styles.container}>
          <div className={styles.headers}>
            <div className={styles.headersSectionHeader}>
              <SectionHeader main="Guides" secondary="Expert Tips" />
            </div>
            <BackButton />
          </div>
          <div className={styles.guides}>
            {nodes.map(node => (
              <GuideItem
                key={node.slug}
                title={node.title}
                slug={node.slug}
                description={node.description.childMarkdownRemark.html}
                skills={node.skills}
              />
            ))}
          </div>
          <Button
            text="Contact Us"
            color="violet"
            onClick={() => (window.location.href = "mailto:info@v4scale.com")}
            className={styles.btn}
          />
          <Notification
            className={styles.notification}
            link={
              <AnchorLink to="/#join-us">
                <h5 className={styles.link}>Why join our team?</h5>
              </AnchorLink>
            }
          />
        </div>
      </section>
    </div>
  )
}
