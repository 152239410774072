import { Link } from "gatsby"
import React from "react"
import * as styles from "./GuideItem.module.scss"
import VacancyChip from "../../OpenVacancies/VacancyChip"

export interface GuideItemProps {
  title: string
  slug: string
  description: string,
  skills: string[],
}

export const GuideItem: React.FC<GuideItemProps> = ({ title, slug, description, skills }) => {
  return (
    <div className={styles.guide}>
      <div className={styles.chips}>
        {skills.map(skill => (
          <VacancyChip key={slug + skill} chip={skill} />
        ))}
      </div>
      <Link
        to={`/guides/${slug}`}
        className={styles.link}
      >
        <div className={styles.heading}>{title}</div>
      </Link>
      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}
